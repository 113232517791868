/**
 * BE STUDIO
 * BLIND TIGER
 * Web Application
 *
 * Menu & Cart Logic
 *
 * @author E. Lew & B. Mockute
 * @copyright (C)2022, BE Studio
 * @see www.its-be-studio.com
 * @version 1.x
 */
const LOAD_MASK = "6378a2b317aecc002fa3a0d6";
const MAIN_BUTTON = "6340290650ef3e00205c9ac0";
const LOGOTYPE = "6367d05a48ff6e0037db11ac";
const CART_BUTTON = "634054b030f6f30013a049f0";
const MENU_BUTTON = "63403bb37bf864002edf5d88";
const SKINCARE = "6342ee5c6e5e290020b0593d";
const JEWELLERY = "6342ee691954b4003db63c38";
const BODYWORK = "6342ee7b2e454a002276fbe3";
const ABOUT = "6342eed0603285003d5b76a6";
const EMAIL = "6342f0fd4b853e001976c83c";
const INSTAGRAM = "6367c8a8ac9a4e7adb2f10c2";
const FACEBOOK = "6367c93e0b3d49002423529a";
const BACKGROUND = "6342ed8272f6fa001c35b9b5";

// Lists in order of menu options...
const ids = [
  LOGOTYPE,
  SKINCARE,
  JEWELLERY,
  BODYWORK,
  ABOUT,
  EMAIL,
  INSTAGRAM,
  FACEBOOK,
  BACKGROUND
];
let allMenuListenersEnabled = false;
const menuListenersEnabled = {
  mainButton: false,
  menuButton: false,
  skincareLink: false,
  jewelleryLink: false,
  bodyworkLink: false,
  aboutLink: false,
  emailLink: false,
  instagramLink: false,
  facebookLink: false,
  background: false
};
let cartButtonObserverEnabled = false;


/**
 * Initialises menu display on first page load.
 */
function initMenu() {
  for(let id of ids) {
    const element = queryElementByDataId(id);
    if(element) {
      element.style.display = "none";
      element.style.opacity = "1";
    }
  }

  hideLoadMask();
}


function hideMenu() {
  for(let id of ids) {
    const element =queryElementByDataId(id);
    element.style.opacity = "0";
    setTimeout(() => element.style.display = "none", 300);
  }

  setTimeout(() => {
    const content = document.querySelector(".content-scroll-wrapper.accelerated-scroll");
    if(content) {
      content.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }, 500);
}


function toggleMenu() {
  let action;

  for(let id of ids) {
    const element = queryElementByDataId(id);
    action = element.style.display === "block" ? "none" : "block";

    if(action === "none") {
      element.style.opacity = "0";
      setTimeout(() => element.style.display = "none", 300);
    } else {
      element.style.display = "block";
      setTimeout(() => element.style.opacity = "1", 50);
    }
  }

  if(action === "none") {
    setTimeout(() => {
      const content = document.querySelector(".content-scroll-wrapper.accelerated-scroll");
      content.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, 500);
  }
}


function hideLoadMask() {
  setTimeout(() => {
    const loadMask = queryElementByDataId(LOAD_MASK);
    if(loadMask) {
      loadMask.style.display = "none";
    }
  }, 350);
}


function addEventListeners() {
  const mainButton = queryElementByDataId(MAIN_BUTTON);
  const menuButton = queryElementByDataId(MENU_BUTTON);
  const skincareLink = queryElementByDataId(SKINCARE);
  const jewelleryLink = queryElementByDataId(JEWELLERY);
  const bodyworkLink = queryElementByDataId(BODYWORK);
  const aboutLink = queryElementByDataId(ABOUT);
  const emailLink = queryElementByDataId(EMAIL);
  const instagramLink = queryElementByDataId(INSTAGRAM);
  const facebookLink = queryElementByDataId(FACEBOOK);
  const background = queryElementByDataId(BACKGROUND);

  if(mainButton) {
    addListener(mainButton)
    menuListenersEnabled.mainButton = true;
  }
  if(menuButton) {
    addListener(menuButton, toggleMenu);
    menuListenersEnabled.menuButton = true;
  }
  if(skincareLink) {
    addListener(skincareLink);
    menuListenersEnabled.skincareLink = true;
  }
  if(jewelleryLink) {
    addListener(jewelleryLink);
    menuListenersEnabled.jewelleryLink = true;
  }
  if(bodyworkLink) {
    addListener(bodyworkLink);
    menuListenersEnabled.bodyworkLink = true;
  }
  if(aboutLink) {
    addListener(aboutLink);
    menuListenersEnabled.aboutLink = true;
  }
  if(emailLink) {
    addListener(emailLink);
    menuListenersEnabled.emailLink = true;
  }
  if(instagramLink) {
    addListener(instagramLink);
    menuListenersEnabled.instagramLink = true;
  }
  if(facebookLink) {
    addListener(facebookLink);
    menuListenersEnabled.facebookLink = true;
  }
  if(background) {
    addListener(background);
    menuListenersEnabled.background = true;
  }

  allMenuListenersEnabled = Object.values(menuListenersEnabled).every(value => value);
}


function addListener(element, callback = hideMenu) {
  element.addEventListener("click", callback);
}


/**
 * Certain Readymag widgets have no transitions enabled in their CSS by default
 * and this interferes with animations being applied by this script. This
 * function removes the problematic CSS.
 */
function removeNoTransitions() {
  const widgets = document.querySelectorAll(".rmwidget.above-all-fade.no-transition");
  for(let widget of widgets) {
    widget.classList.remove("no-transition");
  }
}


function setupCartButtonObserver() {
  const cartButton = queryElementByDataId(CART_BUTTON, " .text");

  const observer = new MutationObserver(() => {
    replaceZeroChar();
  });

  if(cartButton) {
    observer.observe(cartButton, {
      childList: true
    });

    cartButtonObserverEnabled = true;
  }
}


function replaceZeroChar() {
  const cartButton = queryElementByDataId(CART_BUTTON, " .text");
  // noinspection EqualityComparisonWithCoercionJS
  if(cartButton && cartButton.innerHTML == "") {
    // Use uppercase 'O' instead of zero character as it looks stylistically
    // better in the chosen font.
    cartButton.innerHTML = "O";
  }
}


function setupDetectChanges() {
  let previousUrl = "";

  const observer = new MutationObserver(mutationList => {
    // Detects when user responds to the Cookiebot cookie consent dialog. The UI
    // has not initialised during display of this dialog, so all UI events need
    // to be deferred until after the dialog has been responded to.
    for(let mutation of mutationList) {
      const cookieDialog =  document.querySelector("#CybotCookiebotDialog");
      if(mutation.type === "attributes"
        && mutation.attributeName === "style"
        && mutation.target === cookieDialog
        && cookieDialog.style.display === "none"
      ) {
        setTimeout(() => {
          initMenu();
          if(!allMenuListenersEnabled) {
            addEventListeners();
          }
          if(!cartButtonObserverEnabled) {
            replaceZeroChar();
            setupCartButtonObserver();
          }
        }, 500);
      }
    }

    // Detects when there is a URL change as page changes on Readymag do not
    // always involve a full page reload, so the URL change is used to perform
    // certain functions required on page change.
    if(location.href !== previousUrl) {
      previousUrl = location.href;
      window.scrollTo(0, 0);
      initMenu();
      setTimeout(() => {
        removeNoTransitions();
        if(!cartButtonObserverEnabled) {
          replaceZeroChar();
          setupCartButtonObserver();
        }
      }, 250);
    }
  });

  const config = {
    subtree: true,
    childList: true,
    attributes: true
  };
  observer.observe(document, config);
}


function queryElementByDataId(id, addedSelectors = "") {
  return document.querySelector(`[data-id='${id}']${addedSelectors}`);
}


setTimeout(() => {
  initMenu();
  addEventListeners();
  removeNoTransitions();
  setupDetectChanges();
  replaceZeroChar();
  setupCartButtonObserver();
}, 500);
